import React, { useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process as kendoDataQueryProcess } from '@progress/kendo-data-query';
import WORKORDER_DETAILS_ICON from '../../../assets/icons/workorder-details.svg';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from "@progress/kendo-react-common";

const initialFilter = {
  logic: 'and',
  filters: [
      {
          field: 'workOrderStatus',
          operator: 'eq',
          value: 'Open'
      }
  ]
}

export const DropdownFilterCell = (props) => {
  let hasValue = (value) => Boolean(value && value !== props.defaultItem);
  const onChange = (event) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : '',
      operator: hasValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };

  return (
    <div className='k-filtercell'>
      <DropDownList
        style={{ fontWeight: 400 }}
        data={props.data}
        onChange={onChange}
        value={props.value || props.defaultItem}
        defaultItem={props.defaultItem}
      />
      <Button
        title='Clear'
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon='filter-clear'
      />
    </div>
  );
};

const CategoryFilterCell = (props) => (
  <DropdownFilterCell
    {...props}
    data={props.data}
    defaultItem={props.title}
  />
);

function CustomViewCell(props) {
  const [notificationStyle, setNotificationStyle] = useState({});
  // const [notificationError, setNotificationError] = useState({});
  const { homePhoneNumber } = props.dataItem;
  const iconStyle =  homePhoneNumber ? {
    alignSelf: 'center',
    cursor: 'pointer'
  } : {
    alignSelf: 'center',
    color: '#a1a1a1'
  };
  const handleIconClick = () => {
    if(!homePhoneNumber) return console.warn('No Home Phone Number');
    navigator.clipboard.writeText(homePhoneNumber);
    setNotificationStyle({ display: 'block' });
    setTimeout(() => {
      setNotificationStyle({ display: 'none' });
    }, '1400');
  }
  // const handleIconClickError = () => {
  //   setNotificationError({ display: 'block' });
  //   setTimeout(() => {
  //     setNotificationError({ display: 'none' });
  //   }, '1400');
  // }
  return (
    <td
      {...props.tdProps}
      colSpan={1}
      style={{
        color: props.color,
        backgroundColor: props.backgroundColor,
        textAlign: 'center',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
        <div>
          <img
            onClick={() => props.handleOpenWODetails(props.dataItem.id)}
            style={{ paddingTop: '11px', cursor: 'pointer' }}
            src={WORKORDER_DETAILS_ICON}
            alt='workorder-details'
          />
        </div>
        <Icon onClick={handleIconClick} style={iconStyle} name="copy" />

      </div>
      <div style={notificationStyle} className='copied-to-clipboard-notification'>
        <p>Copied to clipboard!</p>
      </div>
      {/* <div style={notificationError} className='copied-to-clipboard-notification-error'>
        <p>No home phone number!</p>
      </div> */}

    </td>
  );
}

function WorkOrdersGrid({ data: workOrders, handleOpenWODetails, dropDownFilters }) {
  const [dataState, setDataState] = useState({ filter: initialFilter });
  const [dataResult, setDataResult] = useState(
    kendoDataQueryProcess(workOrders, dataState)
  );
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  const onDataStateChange = (e) => {
    const { filter } = e.dataState;
    setDataState({ filter });
    setDataResult(kendoDataQueryProcess(workOrders, { filter }));
    setPage({
      skip: 0,
      take: 10,
    });
  };

  const pageChange = (event) => {
    const take = event.page.take
    setPage({
      ...event.page,
      take,
    });
  }

  const workOrderStatusList = [...new Set(workOrders.map(item => item.workOrderStatus))];
  const workOrderCallList = [...new Set(workOrders.map(item => item.bookCode))].filter(group => group !== null);
  const workOrderDistrictList = [...new Set(workOrders.map(item => item.districtCode))].filter(group => group !== null);

  return (
    <div style={{ overflow: 'scroll' }}>
      <Grid
        style={{ minWidth: '2500px' }}
        filterable={true}
        data={dataResult.data.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={dataResult.data.length}
        pageable={{
          buttonCount: 4,
          pageSizeValue: 10,
        }}
        onPageChange={pageChange}
        {...dataState}
        onDataStateChange={onDataStateChange}
      >
        <Column
          field='view'
          title='View'
          cell={(props) => <CustomViewCell {...props} handleOpenWODetails={handleOpenWODetails} />}
          filterable={false}
        />
        <Column field='workOrderNumber' title='Work Order' />
        <Column field='workOrderStatus' title='Status' filterCell={(props) => <CategoryFilterCell {...props} title='Select...' data={workOrderStatusList} />} />
        <Column field='bookCode' title='Call Status' filterCell={(props) => <CategoryFilterCell {...props} title='Select...' data={workOrderCallList} />} />
        <Column field='districtCode' title='District' filterCell={(props) => <CategoryFilterCell {...props} title='Select...' data={workOrderDistrictList} />} />
        <Column field='workOrderAssignments' title='Assignements' />
        <Column field='address' title='Address' />
        <Column field='city' title='City' />
        <Column field='homePhoneNumber' title='Home Phone' />
        <Column field='quantity' title='Quantity' />
        <Column field='dateEnrolled' title='Date Enrolled' />
      </Grid>
    </div>
    
  )
}

export default WorkOrdersGrid;