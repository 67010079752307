import React, { useState, useEffect, useContext } from 'react';

import { Dialog } from "@progress/kendo-react-dialogs";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';

import { createAppointmentWithNotification as createAppointment, updateAppointmentWithNotification as updateAppointment } from '../../../core/services/appointment.service';
import AppContext from '../../../core/context/app.context';



const formatTime = (date, timeZone) => {
    if (typeof date === 'string') date = new Date(date);
    if (!(date instanceof Date)) throw new Error(`Invalid date: ${date}`);
    const dateTimeFormat = new Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit', ...timeZone ? { timeZone } : {} })
    const [{ value: hour }, , { value: minute }, , { value: dayPeriod }] = dateTimeFormat.formatToParts(date)
    return `${hour}:${minute} ${dayPeriod}`;
}

const formatDate = (date, timeZone) => {
    if (typeof date === 'string') date = new Date(date);
    if (!(date instanceof Date)) throw new Error(`Invalid date: ${date}`);
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', ...timeZone ? { timeZone } : {} })
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
    return `${month}/${day}/${year}`;
}





export const FormModal = ({ closeModal, formFill, setLoading, fetchAvailability }) => {
    const context = useContext(AppContext);
    const [success, setSuccess] = useState(false);
    const [formState, setFormState] = useState({
        contactName: '',
        phoneNumber: '',
        email: '',
        additionalNotes: '',
        optIn: false,
        ...formFill
    })
    const [touched, setTouched] = useState({
        email: false,
        phoneNumber: false,
        additionalNotes: false,
        contactName: false,
    });
    const [isValid, setIsValid] = useState({
        contactName: false,
        email: false,
        phoneNumber: false,
        additionalNotes: false,
        submit: false,
    });
    
    useEffect(() => {
        const validationCheckInitial = {
            email: false,
            phoneNumber: false,
            additionalNotes: false,
            contactName: false,
            submit: false,
            optIn: false
        }
        let validForm = true;
        const emailRegex = new RegExp(/\S+@\S+\.\S+/);
        const phoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const { email, phoneNumber, contactName, optIn } = formState;
        const validationCheck = validationCheckInitial;
        if ((email && emailRegex.test(email)) || formState.noEmail) {
            validationCheck.email = true;
        } else {
            validForm = false;
        }
        if (phoneNumber && phoneNumberRegex.test(phoneNumber)) {
            validationCheck.phoneNumber = true;
        } else {
            validForm = false;
        }
        if (contactName) {
            validationCheck.contactName = true;
        } else {
            validForm = false;
        }
        if (optIn) {
            validationCheck.optIn = true;
        } else {
            validForm = false;
        }
        if(validForm) validationCheck.submit = true;
        setIsValid(validationCheck);
    }, [formState]);

    const handleTouch = (e) => {
        setTouched({
            ...touched,
            [e.target.name]: true,
        });
    };

    const handleForm = (e) => {  
        let value = e.target.value;
        let name = e.target.name;
        if (name === 'optIn') {
            value = e.target.checked
        }
        if(e.nativeEvent.target.name === 'additionalNotes'){
            value = e.nativeEvent.target.value;
            name = e.nativeEvent.target.name;
        }
        setFormState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const appointmentId = formState.appointmentId ? formState.appointmentId : false;
            const createAppointmentBody = {
                schedulerUserId: context.user.id,
                projectId: formState.projectId,
                workOrderId: formState.workOrderId,
                appointmentStartDate: formState.appointmentStartDate,
                appointmentEndDate: formState.appointmentEndDate,
                appointmentNotes: (
                    `Appointment details (scheduled by CSR on ${new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}): \n` +
                    `Contact Info: ${formState.contactName} | ${formState.email} | ${formState.phoneNumber}\n` +
                    (formState.additionalNotes ? `Additional Notes: \n${formState.additionalNotes}` : '')
                ),
            };
            appointmentId ? await updateAppointment({ ...createAppointmentBody, id: appointmentId }) : await createAppointment(createAppointmentBody);
            await fetchAvailability({ workOrderId: formState.workOrderId });
            setLoading(false);
            setSuccess(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            { success ? (
                <Dialog title={'Appointment Success!'} onClose={closeModal}>
                    <h1>You may close this window.</h1>
                </Dialog>
                
            ) : (
                <Dialog width={'90%'} height={'90%'} title={'Schedule Appointment'} onClose={closeModal}>
                    <div id='confirm_appointment'>
                        <h1>{formatDate(formState.appointmentStartDate, context.currentProject.projectConfiguration.projectTimeZone)} from {formatTime(formState.appointmentStartDate, context.currentProject.projectConfiguration.projectTimeZone)} to {formatTime(formState.appointmentEndDate, context.currentProject.projectConfiguration.projectTimeZone)}</h1>

                        <div className='confirm-details-contact-container'>
                            <FieldWrapper style={{width: '100%'}}>
                                <Label editorId={"contactName"} className={`required-field`}>
                                Contact Name <span className={`asterisk${isValid.contactName ? ' valid' : ''}`}>*</span>
                                </Label>
                                <div className={"k-form-field-wrap"}>
                                <Input
                                    disabled={false}
                                    name={"contactName"}
                                    onChange={handleForm}
                                    value={formState.contactName}
                                    onFocus={handleTouch}
                                    id={"contactName"}
                                />
                                </div>
                            </FieldWrapper>
                            {!isValid.contactName && touched.contactName ? (
                                <Error>Please enter a contact name.</Error>
                                ) : (
                                <div style={{height: '22px'}}></div>
                                )
                            }

                            <FieldWrapper style={{width: '100%'}}>
                                <Label editorId={"phoneNumber"} className={`required-field`}>
                                Phone Number <span className={`asterisk${isValid.phoneNumber ? ' valid' : ''}`}>*</span>
                                </Label>
                                <div className={"k-form-field-wrap"}>
                                <MaskedTextBox
                                    mask="(999) 000-0000"
                                    disabled={false}
                                    name={"phoneNumber"}
                                    onChange={handleForm}
                                    value={formState.phoneNumber}
                                    onFocus={handleTouch}
                                    id={"phoneNumber"}
                                />
                                </div>
                            </FieldWrapper>
                            {!isValid.phoneNumber && touched.phoneNumber ? (
                                <Error>Please enter a valid phone number.</Error>
                                ) : (
                                <div style={{height: '22px'}}></div>
                                )
                            }

                            {!formState.noEmail ? 
                                <FieldWrapper style={{width: '100%'}} className="confirm-details-contact-email">
                                <Label editorId={"email"} className={`required-field`}>
                                    Email <span className={`asterisk${isValid.email ? ' valid' : ''}`}>*</span>
                                </Label>
                                <div className={"k-form-field-wrap"}>
                                    <Input
                                    disabled={false}
                                    name={"email"}
                                    onChange={handleForm}
                                    value={formState.email}
                                    onFocus={handleTouch}
                                    id={"email"}
                                    />
                                </div>
                                </FieldWrapper> : <div style={{height: '74px'}}></div>
                            }
                            {!isValid.email && touched.email && !formState.noEmail ? (
                                <Error>Please enter a valid email.</Error>
                                ) : (
                                <div style={{height: '22px'}}></div>
                                )
                            }

                            <FieldWrapper style={{width: '100%'}}>
                                <Label>Additional Notes:</Label>
                                <div className={"k-form-field-wrap"}>
                                <TextArea
                                    style={{height:134}}
                                    disabled={false}
                                    name={"additionalNotes"}
                                    onChange={handleForm}
                                    value={formState.additionalNotes}
                                    onFocus={handleTouch}
                                    rows={7}
                                />
                                </div>
                            </FieldWrapper>
                        </div>

                        <div style={{ display: 'flex' }} className="checkbox-container">
                            <input
                                type="checkbox"
                                id="optIn"
                                name="optIn"
                                checked={formState.optIn}
                                value={formState.optIn}
                                onChange={handleForm}
                            />
                            <label htmlFor="optIn" style={{ fontSize:'1em', paddingLeft: '5px' }}>
                                Customer agrees to allow the use of phone number and email to contact them regarding the appointment.
                            </label>
                        </div>

                        <Button primary={true} disabled={!isValid.submit} onClick={handleSubmit}>Submit</Button>
                    </div>
                </Dialog>
            )}
        </>
    );
}