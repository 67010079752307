import gql from 'graphql-tag';

export const LIST_APPOINTMENTS_BY_PROJECT_QUERY = gql`
  query ListAppointmentsByProject($projectId: ID!, $listStartDate: String, $listEndDate: String, $filterDuplicateAddress: Boolean) {
    listAppointmentsByProject(projectId: $projectId, listStartDate: $listStartDate, listEndDate: $listEndDate, filterDuplicateAddress: $filterDuplicateAddress) {
      items {
        id
        appointmentEndDate
        appointmentStartDate
        appointmentNotes
        appointmentSequence
        workOrderId
        workOrderNumber
        workOrderStatus
        projectId
        schedulerUserId
        listTechniciansByAppointment{
          items {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const LIST_WORKORDERS_DUPLICATE_ADDRES = gql`
  query listWorkOrdersDuplicateAddress($workOrderId: ID!) {
    listWorkOrdersDuplicateAddress(workOrderId: $workOrderId) {
      items {
        id
        street
        street2
        street3
        city
        stateCode
        zipCode
        workOrderNumber
      }
    }
  }
`;

export const GET_APPOINTMENT_BY_WORKORDER_QUERY = gql`
  query GetAppointmentByWorkOrder($projectId: ID!, $workOrderId: ID!) {
    getAppointmentByWorkOrder(projectId: $projectId, workOrderId: $workOrderId) {
      id
      appointmentStartDate
    }
  }
`;

export const GET_APPOINTMENT_AVAILABILITY = gql`
  query GetAppointmentAvailability($workOrderId: ID!, $startDate: AWSDateTime!, $endDate: AWSDateTime!) {
    getAppointmentAvailability(workOrderId: $workOrderId, startDate: $startDate, endDate: $endDate) {
      start
      end
      totalSlots
      slotsOpen
      slotsReserved
    }
  }
`;

export const LIST_APPOINTMENT_AVAILABILITY = gql`
  query ListAppointmentAvailability($projectId: ID!) {
    listAppointmentAvailability(projectId: $projectId) {
      items {
        region
        date
        timeslot
        capacity
      }
    }
  }
`;