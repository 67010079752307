import React, { Component } from 'react';
import { Button, Icon, Image, Dropdown, Message} from 'semantic-ui-react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import BACKGROUND_IMAGE from '../../assets/ReportBackground.png';
import ErrorMessage from '../../shared/components/messages/error';
import config from '../../config';
import { getToken } from '../../core/services/auth.service';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import {
  Grid, GridColumn as Column
} from '@progress/kendo-react-grid';
import { CustomColumnMenu } from './customColumnMenu';
import * as userService from '../../core/services/user.service';
import { updateProjectReportPreferences } from '../../core/services/projectReportPreferences';
import './styles.css';

class AppointmentReportRoute extends Component {
  modalRef = React.createRef();
  static contextType = AppContext;

  state = {
    fromDate: '',
    toDate: '',
    error: '',
    exporting: false,
    selectedWorkOrderStatus: 'All',
    loadingPreviewData: false,
    columns: [],
    showCustomReportViewModal: false,
    reportViews: [],
    selectedReportView: null,
    email: ''
  }

  async componentDidMount() {
    const { currentProject } = this.context;
    await this.initReportViews();
    let sendWorkOrderReportViaEmail;
    if (currentProject) {
      sendWorkOrderReportViaEmail = currentProject.projectConfiguration.sendWorkOrderReportViaEmail;
      this.setState({ sendWorkOrderReportViaEmail });
    }
    this.setState({ selectedReportView: 'All', email: sendWorkOrderReportViaEmail ? '' : '' });
    this.preview();
  }

  initReportViews = async () => {
    const { currentProject } = this.context;
    if (currentProject) {
      const reportViews = [
        {
            "id": "Blackout",
            "reportName": "In Blackout"
        },
        {
            "id": "Missed",
            "reportName": "Missed Appointments"
        }
      ];
      this.setState({ reportViews });
    }
  }

  setSelectedReportView = (selectedReportView) => {
    this.setState({ selectedReportView });
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Appointments Report` : 'Appointments Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  onDateChange = (event) => {
    const { end, start } = event.target.value;
    this.setState({ fromDate: start, toDate: end });
  }

  // current format is yyyy-MM-dd
  formatDate(date) {
    if (date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  preview = async () => {
    try {
      let { selectedWorkOrderStatus, selectedReportView } = this.state;
      const { currentProject } = this.context;
      const projectId = currentProject && currentProject.id;
      if (selectedWorkOrderStatus) {
        const workOrderType = currentProject && currentProject.projectWorkOrderType ? currentProject.projectWorkOrderType : null;
        if (workOrderType) {
          this.setState({ loadingPreviewData: true, error: false, appointments: [], columns: [] });
          const preview = true;
          let token;
          try {
            token = await getToken();
          } catch (error) {
            this.setState({ loadingPreviewData: false });
            throw error;
          }
          try {
            let response = await fetch(`${config.exportAppointmentsReportApi}/${projectId}/${preview}/${selectedReportView}`, {
              method: 'get',
              headers: new Headers({
                'Authorization': token
              })
            });
            if (!response.ok) {
              throw new Error('No data found');
            }
            const { appointments, columns } = await response.json();
  
            this.setState({ loadingPreviewData: false, appointments: appointments || [], columns: columns || [] });
          } catch (error) {
            console.error('An error occurred:', error);
            this.setState({ loadingPreviewData: false, error: 'Failed to fetch data' });
          }
        }
      } else {
        this.setState({ error: 'Work Order Status is required' });
      }
    } catch (error) {
      this.setState({ loadingPreviewData: false });
      throw error;
    }
  }

  downloadReport = async (projectId) => {
    try {
      const { selectedReportView } = this.state;
      const preview = false;
      let token;
      try {
        token = await getToken();
      } catch (error) {
        this.setState({ loadingPreviewData: false });
        throw error;
      }
      let response = await fetch(`${config.exportAppointmentsReportApi}/${projectId}/${preview}/${selectedReportView}`, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
      const url = await response.json();
      if (url) {
        window.open(url);
      }
    } catch (error) {
      throw error;
    }
  }

  export = async () => {
    this.setState({ error: '', showEmailSuccessMessage: false });
    const { sendWorkOrderReportViaEmail } = this.state;
    try {
      const { currentProject } = this.context;
      const workOrderType = currentProject && currentProject.projectWorkOrderType ? currentProject.projectWorkOrderType : null;
      if (workOrderType) {
        this.setState({ exporting: true });
        await this.downloadReport(currentProject.id);
        this.setState({ exporting: false });
        if (sendWorkOrderReportViaEmail) {
          this.setState({ showEmailSuccessMessage: true });
        }
      }
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  onFormatChange = (event, data) => {
    this.setState({ format: data.value });
  }

  setAppointmentsOnly = () => {
    const { appointmentsOnly } = this.state;
    this.setState({ appointmentsOnly: !appointmentsOnly });
  }

  onColumnsSubmit = async (columnsState) => {
    const { user, currentProject } = this.context;
    const { selectedReportView } = this.state;
    const isUserReportView = user.id;
    if (isUserReportView) {
      const userId = user.id;
      const projectId = currentProject.id;
      const reportingPreferences = JSON.stringify({
        projectId,
        type: 'workOrderReport',
        data: columnsState
      });
      await userService.updateUser(userId, reportingPreferences);
    } else {
      await updateProjectReportPreferences(selectedReportView, JSON.stringify(columnsState));
    }
    this.setState({
      columns: columnsState
    });
    this.preview();
  }

  onColumnReorder = (event, allColumns) => {
    const { user, currentProject } = this.context;
    const userId = user.id;
    const projectId = currentProject.id;
    const columnsOrder = event.target._columns.map(column => column.title);
    const { selectedReportView } = this.state;
    const isUserReportView = user.id;

    let newColumnsOrder = Array.from(columnsOrder, x => Object.create({}));
    let hiddenColumns = [];

    allColumns.forEach((column) => {
      const order = columnsOrder.indexOf(column.title);
      if (order === -1) {
        hiddenColumns.push(column);
      } else {
        newColumnsOrder[order] = column;
      }
    });
    newColumnsOrder = [...newColumnsOrder, ...hiddenColumns];

    if (isUserReportView) {
      const reportingPreferences = JSON.stringify({
        projectId,
        type: 'workOrderReport',
        data: newColumnsOrder
      });
      userService.updateUser(userId, reportingPreferences);
    } else {
      updateProjectReportPreferences(selectedReportView, JSON.stringify(newColumnsOrder));
    }

    this.setState({});
  }

  setIncludeAuditInformation = () => {
    const { includeAuditInformation } = this.state;
    this.setState({ includeAuditInformation: !includeAuditInformation });
  }

  getReportViewsOptions = () => {
    const { reportViews } = this.state;
    const { user } = this.context;
    if (user) {
      return [{ key: user.id, value: 'All', text: 'All Appointments' }, ...reportViews.map(({ id, reportName }) => Object.assign({}, { key: id, text: reportName, value: id }))];
    }
    return reportViews.map(({ id, reportName }) => Object.assign({}, { key: id, text: reportName, value: id }));
  }

  onReportViewChange = async (event, { value }) => {
    await this.setState({ selectedReportView: value });
    this.preview();
  }

  renderGridColumns = () => {
    const { columns } = this.state;
  
    // Log the columns array to verify its structure and content
    console.log('columns:', columns);
  
    return columns.map((column, index) => {
      // Check if the column should be shown
      if (column.show) {
        return (
          <Column
            key={index}
            field={column.field}
            title={column.title}
            width='240px'
            columnMenu={
              props =>
                <CustomColumnMenu
                  {...props}
                  columns={columns}
                  onColumnsSubmit={this.onColumnsSubmit}
                />
            }
          />
        );
      }
      // Return null for columns that should not be shown
      return null;
    });
  }

  onEmailChange = (event, { value }) => {
    this.setState({ email: value });
  }

  renderAppointmentsReportRouteContent = () => {
    const {
      error,
      exporting,
      loadingPreviewData,
      appointments,
      selectedReportView,
      columns,
    } = this.state;

    const renderToolbar = () => (
      <div className='workorder-report-toolbar-container'>
        <div style={{ display: 'none' }}>
          <div style={{ display: 'flex', fontSize: '0.8rem', marginBottom: '2px', marginLeft: '10px' }}>File Format:</div>
          <Dropdown
            compact
            clearable
            placeholder='Select Format'
            style={{ marginLeft: '10px', width: '100px' }}
            selection
            defaultValue={'csv'}
            options={[
              { key: 0, value: 'csv', text: 'CSV' },
              { key: 1, value: 'xlsx', text: 'XLSX' }]}
            onChange={this.onFormatChange} />
        </div>
        <div className='workorder-report-preview-export-container'>
          <Button primary loading={loadingPreviewData} onClick={this.preview}><Icon name='eye' />Preview</Button>
          <Button primary loading={exporting} onClick={this.export}><Icon name='download' />Export</Button>
        </div>
      </div>
    );

    console.log('State workOrders:', appointments); // Debugging line
    console.log('State columns:', columns); // Debugging line

    return (
      <React.Fragment>
      <div>
        <Message color='grey' content='Appointment reports display all appointment details and can be filtered to show only those scheduled during blackout periods or any missed appointments.' />
      </div>
      <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
        <div style={{ display: 'flex', paddingTop: '10px', marginBottom: '2px', marginRight: '10px' }}>Report View:</div>
        <Dropdown
        placeholder='Select Report View'
        style={{ width: '180px', marginRight: 10 }}
        selection
        search
        value={selectedReportView}
        options={this.getReportViewsOptions()}
        onChange={this.onReportViewChange} />
      </div>
      {renderToolbar()}
      {error && <div style={{ marginTop: '5px' }}>
        <ErrorMessage message={error} style={{ marginBottom: 10 }} />
      </div>}
      {appointments && appointments.length > 0 ? (
        <div>
        <Grid
          data={appointments}
          reorderable
          onColumnReorder={(event) => this.onColumnReorder(event, columns)}
        >
          {this.renderGridColumns()}
        </Grid>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        {!loadingPreviewData && appointments && appointments.length === 0 ? (
          <div>No appointments found.</div>
        ) : (
          <Image src={BACKGROUND_IMAGE} style={{ position: 'absolute' }} />
        )}
        </div>
      )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {this.renderAppointmentsReportRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default AppointmentReportRoute;