import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

const showLabel = (selectedWorkOrder) => {
  if (selectedWorkOrder && selectedWorkOrder.customDataFields) {
    const instrumentRatedMeter = selectedWorkOrder.customDataFields.find(i=>i.key==='instrumentRatedMeter');
    return instrumentRatedMeter && instrumentRatedMeter.value === 'true';
  }
  return false;
}

const Header = ({ currentProject, selectedWorkOrder, isQA }) => {
  const schedulerDomain = process.env.NODE_ENV === 'production' ? 'peakscheduler' : 'peakworkflowdemo';
  const hasScheduler = () => {
    try {
      const { projectConfiguration: { additionalFeatures: { appointmentManagement }}} = currentProject;
      return appointmentManagement? true : false;
    } catch (error) {
      return false;
    }
  }
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex'}}>
        <Icon name='file text' />{currentProject && currentProject.projectShortCode ? currentProject.projectShortCode : ''} | Work Order Details | {(selectedWorkOrder && selectedWorkOrder.workOrderNumber) || ''}
        { hasScheduler() &&
          <div style={{ display: 'flex', color: '#777', marginLeft: '5px', fontSize: '1em' }}>
            | Service code: { selectedWorkOrder && selectedWorkOrder.serviceCode ? selectedWorkOrder.serviceCode : 'NO CODE FOUND' }
            <i onClick={() => window.open(`https://${currentProject.projectShortCode}.${schedulerDomain}.com?code=${ selectedWorkOrder.serviceCode }`)} aria-hidden="true" class="external alternate icon" style={{ cursor: 'pointer', color: '#777', marginLeft: '6px', fontSize: '0.8em' }}></i>
          </div>
        }
      </div>
      { isQA && showLabel(selectedWorkOrder) &&
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Label color='red' horizontal>Instrument Rated</Label>
        </div>
      }
    </div>
  )
}

export default Header;