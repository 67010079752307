import React, { useState, useContext, useEffect } from 'react';

import AppContext from '../../../../core/context/app.context';

import Loader from '../../../../shared/components/loader'

import { Dialog } from '@progress/kendo-react-dialogs';

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { process } from '@progress/kendo-data-query';

import { listAppointmentAvailability, createAppointmentAvailability, deleteAppointmentAvailability, updateAppointmentAvailability } from '../../../../core/services/appointment.service';

// input wrapper style
const iws = { display: 'flex', flexDirection: 'column' }
const formStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: '15px',
    maxWidth: '1000px'
}
const initialForm = {
    capacity: '',
    region: '',
    date: '',
    timeslot: ''
}

const initialGridState = {
    sort: [],
    filter: [],
    skip: 0,
};



export const CapacityModal = ({ onClose }) => {
    const context = useContext(AppContext);
    const [data, setData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [formState, setFormState] = useState(initialForm);
    const [gridState, setGridState] = useState(initialGridState);
    const [originalItem, setOriginalItem] = useState({});
    // const [item]


    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await fetchCapacity();
            setLoading(false);
        }
        init();
    }, []);

    const processData = (data) => {
        setProcessedData(
            process(data, gridState)
        )
    } 

    const fetchCapacity = async () => {
        try {
            const results = await listAppointmentAvailability({ projectId: context.currentProject.id });
            results.forEach((item, index) => {
                const [ year, month, date ] = item.date.slice(0, 10).split('-');
                // Number('0') is a falsey value so this will strip out the 0's in front of numbers if they exist;
                const formattedDate = `${Number(month[0]) ? month: month[1]}/${Number(date[0]) ? date: date[1]}/${year}`;
                item.index = index
                item.projectId = context.currentProject.id
                item.formattedDate = formattedDate;
                item.date = new Date(item.date);
            });
            setData(results);
            processData(results);
            // if this succeeds, then there will no no inEdit
            setInEditMode(false);
        } catch (error) {
            console.error(error);
        }
    }

    const handleAddNew = () => {
        if(addNew) setFormState(initialForm);
        setAddNew(prev => !prev);
    };

    const handleSubmitForm = async (evt) => {
        try {
            setLoading(true);
            evt.preventDefault();
            const {
                date,
                capacity,
                region,
                timeslot
            } = formState;
            let isValid = true;
            if(!date) isValid = false;
            if(!capacity) isValid = false;
            if(!region) isValid = false;
            if(!timeslot) isValid = false;
            if(!isValid) return console.warn('invalid form');
            const formattedDate = date.toISOString().slice(0, 10);
            const inputBody = {
                projectId: context.currentProject.id,
                date: formattedDate,
                capacity,
                region,
                timeslot
            }
            await createAppointmentAvailability(inputBody);
            await fetchCapacity();
            setAddNew(false);
            setFormState(initialForm);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
        
    };

    const onItemChange = (evt) => {
        const updatedData = JSON.parse(JSON.stringify(data));
        updatedData[evt.dataItem.index][evt.field] = evt.value;
        processData(updatedData);
        setData(updatedData);
    };

    const handleEdit = (item) => {
        setInEditMode(true);
        setOriginalItem(item);
        const updatedData = JSON.parse(JSON.stringify(data));
        updatedData[item.index].inEdit = true;
        processData(updatedData);
        setData(updatedData);
    };

    const handleCancel = (item) => {
        setInEditMode(false);
        const updatedData = JSON.parse(JSON.stringify(data));
        updatedData[item.index].inEdit = false;
        updatedData[item.index] = originalItem;
        setOriginalItem({});
        processData(updatedData);
    };

    const handleSubmitEdit = async (evt) => {
        try {
            setLoading(true);
            await updateAppointmentAvailability(evt);
            await fetchCapacity();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleFormState = (evt) => {
        const { target: { name }, value } = evt;
        setFormState(prev => ({ ...prev, [name]: value }))
    }

    const handleDelete = async (item) => {
        setLoading(true);
        await deleteAppointmentAvailability(item);
        await fetchCapacity();
        setLoading(false);
    };

    const handleDataStateChange = (evt) => {
        setGridState(evt.dataState);
        setProcessedData(process(data, evt.dataState));
    }

    return (
        <>
            <Dialog width={'90%'} height={'90%'} title={'Edit Capacity'} onClose={onClose}>
                    <div>
                        <Button style={{ marginBottom: '15px' }} onClick={handleAddNew} primary={addNew ? false : true} >{addNew ? 'Cancel' : 'Add New Slot'}</Button>
                        { addNew && <AddNewCapacityForm formState={formState} handleFormState={handleFormState} onSubmit={handleSubmitForm} /> }
                    </div>
                    <Grid
                        { ...gridState }
                        sortable={true}
                        filterable={true}
                        data={processedData}
                        editField='inEdit'
                        onItemChange={onItemChange}
                        onDataStateChange={handleDataStateChange}
                    >
                        <GridColumn
                            field='region'
                            title='Region'
                            editable={false}
                        />
                        <GridColumn
                            field='date'
                            title='Date'
                            editable={false}
                            filter='date'
                            cell={(props) => <CustomDateCell { ...props } />}
                        />
                        <GridColumn
                            field='timeslot'
                            title='Timeslot'
                            editable={false}
                        />
                        <GridColumn
                            field='capacity'
                            title='Capacity'
                            editable={true}
                            editor='numeric'
                            filter='numeric'
                        />
                        <GridColumn
                            sortable={false}
                            filterable={false}
                            cell={(props) => (
                                <td>
                                    {props.dataItem.inEdit ? (
                                        <>
                                        <Button onClick={() => handleSubmitEdit(props.dataItem)} primary={true}>
                                            Save
                                        </Button>
                                        <Button onClick={() => handleCancel(props.dataItem)}>
                                            Cancel
                                        </Button>
                                        </>
                                    ) : (
                                        <>
                                        <Button disabled={inEditMode} onClick={() => handleEdit(props.dataItem)} primary={true}>
                                            Edit
                                        </Button>
                                        <Button disabled={inEditMode} onClick={() => handleDelete(props.dataItem)}>
                                            Delete
                                        </Button>
                                        </>
                                    )}
                                </td>
                            )}
                        />
                    </Grid>
            </Dialog>
            { loading && <Loader loading={loading} /> }
        </>
    )
}





const CustomDateCell = ( props ) => {
    return (
        <td { ...props }>{props.dataItem.formattedDate}</td>
    )
}



const AddNewCapacityForm = ({ formState, handleFormState, onSubmit }) => {
    return (
        <div>
            <form onSubmit={onSubmit} style={formStyle}>
                <div style={iws}>
                    <Label>Region</Label>
                    <Input
                        className='csr-capacity-inputs'
                        value={formState.region}
                        name='region'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Label>Date</Label>
                    <DatePicker
                        className='csr-capacity-inputs'
                        value={formState.date}
                        name='date'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Label>Timeslot</Label>
                    <Input
                        className='csr-capacity-inputs'
                        value={formState.timeslot}
                        name='timeslot'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Label>Capacity</Label>
                    <Input
                        className='csr-capacity-inputs'
                        value={formState.capacity}
                        name='capacity'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Button onClick={onSubmit} primary={true}>SUBMIT</Button>
                </div>
                
            </form>
        </div>
    )
}