import React, { Component, useState } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridToolbar
} from '@progress/kendo-react-grid';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button, Icon, Message, Dropdown, Input } from 'semantic-ui-react';
import { orderBy } from '@progress/kendo-data-query';
import WorkOrdersAssignmentsModal from '../modal/assignments';
import MeterDeployDetailsModal from '../../../../shared/components/workorder-details/meterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../../shared/components/workorder-details/waterMeterDeployDetails';
import AppContext from '../../../../core/context/app.context';
import {
  ASSIGN_WORKORDERS_MUTATION,
  UNASSIGN_WORKORDERS_MUTATION
} from '../../../../graphql/mutations/workorder';
import { isDisabled, isEnabled } from '../../../../core/services/auth.service';
import Loader from '../../../../shared/components/loader';
import Search from '../search';
import * as workOrderService from '../../../../core/services/workOrder.service';
import * as userService from '../../../../core/services/user.service';
import ROLES from '../../../../constants/roles';
import WORKORDER_DETAILS_ICON from '../../../../assets/icons/workorder-details.svg';
import RemoveWorkOrderConfirmModal from '../modal/removeWorkOrderConfirm';
import WaterDeployDetailsModal from '../../../../shared/components/workorder-details/waterDeployDetails';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import SaveWorkOrdersGroup from '../modal/saveWorkOrdersGroup';
import { isWaterProject } from '../../../../helpers/workOrderType';
import withApollo from '../../../../hoc/withApollo';
import ReOpenClosedWorkOrderConfirmModal from '../modal/reOpenClosedWorkOrderConfirm';
import CreateWorkOrderModal from '../modal/createWorkOrderModal';
import SwapWorkOrdersDataModal from '../modal/swapWorkOrdersDataModal';
import './styles.css';
import QualificationWarningModal from '../../../../shared/components/modal/qualificationWarningModal';

const ExchangeWorkOrdersToolBar = ({
  selectedWorkOrders,
  openSwapWorkOrdersDataModal,
  toggleExchangeWorkOrders,
  onExchangeWorkOrdersSearch,
  showSwapWorkOrdersDataModal,
  swapWorkOrdersDataError,
  swapWorkOrdersData,
  swapingWorkOrdersData,
  closeSwapWorkOrdersDataModal
}) => {
  const [workOrderNumber1, setWorkOrderNumber1] = useState('');
  const [workOrderNumber2, setWorkOrderNumber2] = useState('');

  const onWorkOrderNumber1Change = (event, { value }) => {
    setWorkOrderNumber1(value);
  }

  const onWorkOrderNumber2Change = (event, { value }) => {
    setWorkOrderNumber2(value);
  }

  const getMultipleValuesSearch = () => {
    return [workOrderNumber1.trimLeft().trimRight(), workOrderNumber2.trimLeft().trimRight()];
  }

  return (
    <>
      {showSwapWorkOrdersDataModal && <SwapWorkOrdersDataModal
        error={swapWorkOrdersDataError}
        onSubmit={() => swapWorkOrdersData(getMultipleValuesSearch())}
        loading={swapingWorkOrdersData}
        showModal={showSwapWorkOrdersDataModal}
        closeModal={closeSwapWorkOrdersDataModal} />}
      <Button
        disabled={selectedWorkOrders.length !== 2}
        onClick={openSwapWorkOrdersDataModal}>
        <Icon name='exchange' />
        Exchange
      </Button>
      <div style={{ paddingRight: 5 }}>
        <Input type='text' placeholder='work order number 1' onChange={onWorkOrderNumber1Change} />
      </div>
      <div style={{ paddingRight: 5 }}>
        <Input type='text' placeholder='work order number 2' onChange={onWorkOrderNumber2Change} />
      </div>
      <Button primary onClick={() => onExchangeWorkOrdersSearch(getMultipleValuesSearch())}>Search</Button>
      <Button onClick={toggleExchangeWorkOrders}>Cancel</Button>
    </>
  )
}

const RemoveWorkOrderToolBar = ({
  removingWorkOrder,
  showRemoveWorkOrderConfirmModal,
  closeRemoveWorkOrderConfirmModal,
  removeWorkOrders,
  removeWorkOrderConfirmModalRef,
  selectedWorkOrders,
  openRemoveWorkOrderConfirmModal,
  multipleSearchRef,
  onMultipleValuesSearch,
  toggleRemoveWorkOrderToolBar
}) => (
  <>
    <RemoveWorkOrderConfirmModal
      loading={removingWorkOrder}
      showModal={showRemoveWorkOrderConfirmModal}
      closeModal={closeRemoveWorkOrderConfirmModal}
      onSubmit={removeWorkOrders}
      modalRef={removeWorkOrderConfirmModalRef}
    />
    <Button
      disabled={selectedWorkOrders.length === 0}
      onClick={openRemoveWorkOrderConfirmModal}>
      <Icon name='remove' />
      Remove
    </Button>
    <Search
      searchRef={multipleSearchRef}
      onSearch={onMultipleValuesSearch}
      placeholder='Use a comma to separate multiple items (i.e. 1001,1002)'
    />
    <Button onClick={toggleRemoveWorkOrderToolBar}>Cancel</Button>
    <Message className='remove-workorder-description' color='grey' content='Find and mark as removed work orders that are to be exchanged by utility.' />
  </>
)

class WorkOrdersListView extends Component {
  static contextType = AppContext;
  _isMounted = false;
  lastSelectedIndex = 0;
  searchRef = React.createRef();
  multipleSearchRef = React.createRef();
  removeWorkOrderConfirmModalRef = React.createRef();
  reOpenClosedWorkOrderConfirmModalRef = React.createRef();

  state = {
    selectedWorkOrderIndex: 0,
    selectedWorkOrders: [],
    showAssignModal: false,
    users: [],
    error: false,
    showMeterDeployDetails: false,
    showWaterMeterDeployDetails: false,
    sort: [],
    searchBy: '',
    filterBy: [],
    selectedWorkOrder: null,
    pageLimit: 0,
    modalDetailsLoading: false,
    showRemoveWorkOrderToolBar: false,
    showExchangeWorkOrdersToolBar: false,
    multipleValuesSearch: [],
    showRemoveWorkOrderConfirmModal: false,
    assigning: false,
    filterTypes: [],
    assignUsersList: [],
    unassingUsersList: [],
    photoIndex: 0,
    showWaterDeployDetails: false,
    showSaveGroupModal: false,
    showReOpenClosedWorkOrderModal: false,
    reOpeningClosedWorkOrders: false,
    removingWorkOrder: false,
    reOpenWorkOrderStatus: null,
    showCreateWorkOrderModal: false,
    loadingTechnicians: false,
    showSwapWorkOrdersDataModal: false,
    swapingWorkOrdersData: false,
    swapWorkOrdersDataError: null,
    showQualificationWarningModal: false,
    excludeFromFutureExports: false,
    rework: {
      reworkReason: '',
      reworkDetails: '',
    }
  };

  async componentDidMount() {
    try {
      const { currentProject } = this.context;
      const { pageLimit, filterTypes, getFilterTypes, workOrderId, getWorkOrders } = this.props;
      const { searchBy, filterBy } = this.state;
      this._isMounted = true;
      this.listWorkOrderTechniciansByProject(currentProject.id);
      getFilterTypes();
      this.setState({ pageLimit, filterTypes });
      if (workOrderId) {
        this.openWorkOrderDetails(workOrderId);
      }
      getWorkOrders(null, searchBy, filterBy);
    } catch (error) {
      this.setState({ error: true });
    }
  }

  componentWillReceiveProps(newProps) {
    const { pageLimit, filterTypes } = newProps;
    this.setState({ pageLimit, filterTypes });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  setReOpenWorkOrderStatus = (event, { value }) => {
    this.setState({ reOpenWorkOrderStatus: value ? value : null });
  }

  setModalDetailsLoading = (modalDetailsLoading) => {
    this.setState({ modalDetailsLoading });
  }

  listWorkOrderTechniciansByProject = async (projectId) => {
    const { setProjectUserByWorkOrderStatusAndCount } = this.context;
    this.setState({ loadingTechnicians: true });
    const users = await userService.listProjectUserByWorkOrderStatusAndCount(projectId);
    setProjectUserByWorkOrderStatusAndCount(users);
    this.setState({ users, loadingTechnicians: false });
  }

  closeQualificationWarningModal = () => {
    this.setState({ showQualificationWarningModal: '' });
  }

  hasQualifications = (user) => {
    const { selectedWorkOrders } = this.state;
    const { workOrders } = this.props;
    const { currentProject } = this.context;
    const workOrderType = currentProject.projectWorkOrderType;
    const qualificationDefinitionKey = workOrderType === WORKORDER_TYPES.MeterDeploy || WORKORDER_TYPES.WaterMeterDeploy ? 'meterFormFactor' : 'meterSize';
    const sizes = {};
    if (user.qualificationDefinition) {
      const qualificationDefinition = JSON.parse(user.qualificationDefinition);
      for (let currectWorkOrder of selectedWorkOrders) {
        const workOrder = workOrders.find(i => i.id === currectWorkOrder);
        const size = workOrder[qualificationDefinitionKey];
        if (qualificationDefinition[qualificationDefinitionKey].indexOf(size) === -1) {
          sizes[size] = size;
        }
      }
    }
    if (Object.keys(sizes).length > 0) {
      this.setState({ showQualificationWarningModal: `Technician ${user.fullName} is not qualified for: ${Object.keys(sizes).join(', ')}` });
    }
  }

  addUserToAssignedList = (event, data, user) => {
    const { assignUsersList, unassingUsersList } = this.state;
    const userId = data.value;
    const assingUsersListIndex = assignUsersList.indexOf(userId);
    const unassingUsersListIndex = unassingUsersList.indexOf(userId);
    if (assingUsersListIndex > -1) {
      assignUsersList.splice(assingUsersListIndex, 1);
    } else {
      this.hasQualifications(user);
      assignUsersList.push(userId);
    }
    if (unassingUsersListIndex > -1) {
      unassingUsersList.splice(unassingUsersListIndex, 1);
    }
    this.setState({ assignUsersList });
  }

  addUserToUnAssignedList = (event, data) => {
    const { unassingUsersList, assignUsersList } = this.state;
    const userId = data.value;
    const unassingUsersListIndex = unassingUsersList.indexOf(userId);
    const assingUsersListIndex = assignUsersList.indexOf(userId);
    unassingUsersListIndex > -1 ? unassingUsersList.splice(unassingUsersListIndex, 1) : unassingUsersList.push(userId);
    if (assingUsersListIndex > -1) {
      assignUsersList.splice(assingUsersListIndex, 1);
    }
    this.setState({ unassingUsersList });
  }

  closeAssignModal = () => {
    this.setState({ showAssignModal: false, assignUsersList: [], unassingUsersList: [] });
  };

  openAssignModal = () => {
    this.setState({ showAssignModal: true });
  };

  handleWorkOrderAssignments = async (mutation, users) => {
    let { selectedWorkOrders } = this.state;
    try {
      const { client } = this.props;
      const { currentProject } = this.context;
      const batchSize = 300; // Set the batch size to limit the number of workOrderIds sent at a time
      const totalWorkOrders = selectedWorkOrders.length;
      let startIndex = 0;
      while (startIndex < totalWorkOrders) {
        const endIndex = Math.min(startIndex + batchSize, totalWorkOrders);
        const workOrderIds = selectedWorkOrders.slice(startIndex, endIndex);
        await client.mutate({
          mutation,
          variables: {
            projectId: currentProject.id,
            userIds: users,
            workOrderIds
          }
        });
        startIndex += batchSize;
      }
    } catch (error) {
      console.log(error);
    }
  };

  assignUsers = async () => {
    this.setState({ assigning: true });
    const { currentProject } = this.context;
    const { assignUsersList, unassingUsersList, searchBy, filterBy } = this.state;
    const { getWorkOrders, getFilterTypes } = this.props;
    if (assignUsersList.length > 0) {
      await this.handleWorkOrderAssignments(ASSIGN_WORKORDERS_MUTATION, assignUsersList);
    }
    if (unassingUsersList.length > 0) {
      await this.handleWorkOrderAssignments(UNASSIGN_WORKORDERS_MUTATION, unassingUsersList);
    }
    getWorkOrders(null, searchBy, filterBy);
    getFilterTypes();
    await this.listWorkOrderTechniciansByProject(currentProject.id);
    this.setState({ selectedWorkOrders: [], assignUsersList: [], unassingUsersList: [], assigning: false });
    this.closeAssignModal();
  };

  onGallerySlide = (photoIndex) => {
    this.setState({ photoIndex });
  }

  selectionChange = event => {
    let { selectedWorkOrders } = this.state;
    event.dataItem.selected = !event.dataItem.selected;
    const workOrderIndex = selectedWorkOrders.findIndex(
      workOrderId => workOrderId === event.dataItem.id
    );
    workOrderIndex > -1
      ? selectedWorkOrders.splice(workOrderIndex, 1)
      : selectedWorkOrders.push(event.dataItem.id);
    this.setState({ selectedWorkOrders });
    this.forceUpdate();
  };

  headerSelectionChange = event => {
    let { workOrders } = this.props;
    const checked = event.syntheticEvent.target.checked;
    workOrders.forEach(item => (item.selected = checked));
    const workOrdersIds = checked
      ? workOrders.map(workOrder => workOrder.id)
      : [];
    this.setState({ selectedWorkOrders: workOrdersIds });
    this.forceUpdate();
  };

  rowClick = event => {
    const { workOrders } = this.props;
    let { selectedWorkOrders } = this.state;
    selectedWorkOrders = [];
    let last = this.lastSelectedIndex;
    const current = workOrders.findIndex(
      dataItem => dataItem === event.dataItem
    );
    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }
    if (!event.nativeEvent.ctrlKey) {
      workOrders.forEach(item => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      workOrders[i].selected = select;
      selectedWorkOrders.push(workOrders[i].id);
      this.setState({ selectedWorkOrders });
    }
    this.forceUpdate();
  };

  closeWaterDeployDetails = () => {
    this.setState({ showWaterDeployDetails: false, photoIndex: 0, hideNext: false });
  };

  closeMeterDeployDetails = () => {
    this.setState({ showMeterDeployDetails: false, photoIndex: 0, hideNext: false });
  };

  closeWaterMeterDeployDetails = () => {
    this.setState({ showWaterMeterDeployDetails: false, photoIndex: 0, hideNext: false });
  }

  filterWorkOrderById = id => {
    const { workOrders } = this.props;
    return workOrders.find(workOrder => workOrder.id === id);
  };

  onSortChange = event => {
    this.setState({ sort: event.sort });
  };

  pageChange = async ({ page }) => {
    const { searchBy, filterBy, multipleValuesSearch } = this.state;
    const { getWorkOrders, changePageLimit } = this.props;
    await changePageLimit(page.take);
    getWorkOrders(page.skip, searchBy, filterBy, multipleValuesSearch);
  };

  onMultipleValuesSearch = () => {
    const searchBy = this.multipleSearchRef.current.value;
    const { getWorkOrders } = this.props;
    const multipleValuesSearch = searchBy.split(',').map(value => value.trimLeft().trimRight().replaceAll(`'`, `\\'`));
    this.setState({ selectedWorkOrders: [], multipleValuesSearch });
    getWorkOrders(null, null, null, multipleValuesSearch);
  };

  onExchangeWorkOrdersSearch = (multipleValuesSearch) => {
    const { getWorkOrders } = this.props;
    this.setState({ selectedWorkOrders: [], multipleValuesSearch });
    getWorkOrders(null, null, null, multipleValuesSearch);
  }

  onSearch = () => {
    const searchBy = this.searchRef.current.value.trimLeft().trimRight().replaceAll(`'`, `\\'`);
    const { filterBy } = this.state;
    const { getWorkOrders } = this.props;
    this.setState({ searchBy, selectedWorkOrders: [] });
    getWorkOrders(null, searchBy, filterBy);
  };

  onFilterTypeChange = event => {
    const { getWorkOrders } = this.props;
    let { filterBy } = this.state;
    const newFilterList = event.target.value;
    filterBy =
      newFilterList.length > 0
        ? newFilterList.map(filter => filter.filterName)
        : [];
    if (filterBy.length === 0) {
      getWorkOrders();
    }
    this.setState({ filterBy });
  };

  delayCursorReset = () => {
    setTimeout(() => {
      document.querySelector("#root").style.cursor = 'default';
    }, 3000);
  }

  openWorkOrderDetails = async (workOrderId) => {
    const { getWorkOrderDetails } = this.props;
    document.querySelector("#root").style.cursor = 'wait';
    this.setState({ modalDetailsLoading: true });
    const selectedWorkOrder = await getWorkOrderDetails(workOrderId);
    let newState = { selectedWorkOrder, modalDetailsLoading: false, hideNext: true };
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { ...newState, showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { ...newState, showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { ...newState, showWaterDeployDetails: true };
    }
    this.setState(newState);
    this.delayCursorReset();
  }

  onPreviewClick = async ({ dataItem }) => {
    document.querySelector("#root").style.cursor = 'wait';
    this.setState({ modalDetailsLoading: true });
    const { workOrders, getWorkOrderDetails } = this.props;
    const selectedWorkOrderIndex = workOrders.findIndex(workOrder => workOrder === dataItem);
    const selectedWorkOrder = await getWorkOrderDetails(dataItem.id);
    let newState = { selectedWorkOrder, selectedWorkOrderIndex, modalDetailsLoading: false };
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { ...newState, showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { ...newState, showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { ...newState, showWaterDeployDetails: true };
    }
    this.setState(newState);
    this.delayCursorReset();
  };

  PreviewButtonRenderer = props => (
    <td style={{ textAlign: 'center' }}>
      <img
        onClick={() => this.onPreviewClick(props)}
        style={{ paddingTop: '11px', cursor: 'pointer' }}
        src={WORKORDER_DETAILS_ICON}
        alt='workorder-details'
      />
    </td>
  );

  handleKeyPress = event => {
    const { showSaveGroupModal, showRemoveWorkOrderToolBar, showAssignModal, showRemoveWorkOrderConfirmModal } = this.state;
    if (event.key === 'Enter' && !showSaveGroupModal && !showAssignModal && !showRemoveWorkOrderConfirmModal) {
      if (showRemoveWorkOrderToolBar) {
        this.onMultipleValuesSearch();
      } else {
        this.onSearch();
      }
    }
  };

  onGetWorkOrderDetails = async (workOrderId) => {
    const { selectedWorkOrder } = this.state;
    this.setState({ modalDetailsLoading: true });
    const { getWorkOrderDetails } = this.props;
    const result = await getWorkOrderDetails(workOrderId, selectedWorkOrder.workOrderAssignments);
    this.setState({ selectedWorkOrder: result, modalDetailsLoading: false });
  }

  updateWorkOrder = async (workOrderId, update) => {
    if (typeof (update) === 'function') {
      await update();
    }
    const { getWorkOrders } = this.props;
    const { searchBy, filterBy } = this.state;
    this.onGetWorkOrderDetails(workOrderId)
    getWorkOrders(null, searchBy, filterBy);
  }

  toggleRemoveWorkOrderToolBar = () => {
    const { showRemoveWorkOrderToolBar } = this.state;
    const { getWorkOrders } = this.props;
    const newState = { showRemoveWorkOrderToolBar: !showRemoveWorkOrderToolBar };
    if (showRemoveWorkOrderToolBar) {
      newState.selectedWorkOrders = [];
    }
    this.setState(newState);
    if (showRemoveWorkOrderToolBar) {
      getWorkOrders();
    }
  }

  removeWorkOrders = async () => {
    this.setState({ removingWorkOrder: true });
    const { currentProject } = this.context;
    const { selectedWorkOrders } = this.state;
    const { getWorkOrders } = this.props;
    const { multipleValuesSearch } = this.state;
    await workOrderService.deleteWorkOrder(currentProject.id, selectedWorkOrders);
    this.setState({ selectedWorkOrders: [], showRemoveWorkOrderConfirmModal: false, removingWorkOrder: false });
    getWorkOrders(null, null, null, multipleValuesSearch);
  }

  openRemoveWorkOrderConfirmModal = async () => {
    this.setState({ showRemoveWorkOrderConfirmModal: true });
    this.removeWorkOrderConfirmModalRef.current.focus();
  }

  closeRemoveWorkOrderConfirmModal = () => {
    this.setState({ showRemoveWorkOrderConfirmModal: false });
  }

  openSaveGroupModal = () => {
    this.setState({ showSaveGroupModal: true });
  }

  closeSaveGroupModal = () => {
    this.setState({ showSaveGroupModal: false });
  }

  unselectWorkOrders = () => {
    this.setState({ selectedWorkOrders: [] });
  }

  openSwapWorkOrdersDataModal = () => {
    this.setState({ showSwapWorkOrdersDataModal: true, swapWorkOrdersDataError: null });
  }

  closeSwapWorkOrdersDataModal = () => {
    this.setState({ showSwapWorkOrdersDataModal: false, swapWorkOrdersDataError: null });
  }

  toggleExchangeWorkOrders = () => {
    const { showExchangeWorkOrdersToolBar } = this.state;
    const { getWorkOrders } = this.props;
    const newState = { showExchangeWorkOrdersToolBar: !showExchangeWorkOrdersToolBar };
    if (showExchangeWorkOrdersToolBar) {
      newState.selectedWorkOrders = [];
    }
    this.setState(newState);
    if (showExchangeWorkOrdersToolBar) {
      getWorkOrders();
    }
  }

  onNext = () => {
    let { selectedWorkOrderIndex } = this.state;
    let { workOrders } = this.props;
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex + 1) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      selectedWorkOrderIndex = selectedWorkOrderIndex + 1;
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    this.onPreviewClick({ dataItem: nextWorkOrder });
  }

  moreDropDownOptions = () => {
    const { selectedWorkOrders } = this.state;
    const { user } = this.context;
    const isReOpenDisabled = selectedWorkOrders.length === 0;
    const options = [
      { key: 0, text: 'Find/Remove', onClick: this.toggleRemoveWorkOrderToolBar },
      { key: 1, text: 'Re-Open', disabled: isReOpenDisabled, onClick: isReOpenDisabled ? () => { } : this.openReOpenClosedWorkOrderConfirmModal },
    ];
    if (user && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin])) {
      options.push({ key: 2, text: 'Create Work Order', onClick: this.openCreateWorkOrder });
      options.push({ key: 3, text: 'Find/Exchange', onClick: this.toggleExchangeWorkOrders });
    }
    return options;
  }

  onExcludeFromFutureExportsChange = (event, { checked }) => {
    const newState = { excludeFromFutureExports: checked };
    if (!checked) {
      newState.rework = { reworkReason: '', reworkDetails: '' };
      newState.reOpenWorkOrderStatus = null;
    } else {
      newState.reOpenWorkOrderStatus = 'Escalated';
    }
    this.setState(newState);
  }


  reOpenClosedWorkOrder = async () => {
    this.setState({ reOpeningClosedWorkOrders: true });
    const { currentProject, user } = this.context;
    const { selectedWorkOrders, searchBy, filterBy, reOpenWorkOrderStatus, excludeFromFutureExports, rework } = this.state;
    const { getWorkOrders } = this.props;
    const inputParams = { projectId: currentProject.id, workOrderIds: selectedWorkOrders, userId: user.id };
    if (reOpenWorkOrderStatus) {
      inputParams.workOrderStatus = reOpenWorkOrderStatus;
    }
    if (excludeFromFutureExports) {
      inputParams.rework = { reworkReason: rework.reworkReason || '', reworkDetails: rework.reworkDetails || '' };
    }
    await workOrderService.reOpenClosedWorkOrder(inputParams);
    this.setState({
      selectedWorkOrders: [],
      showReOpenClosedWorkOrderModal: false,
      reOpeningClosedWorkOrders: false,
      reOpenWorkOrderStatus: null,
      rework: { reworkReason: '', reworkDetails: '' },
      excludeFromFutureExports: false
    });
    getWorkOrders(null, searchBy, filterBy);
  }

  closeReOpenClosedWorkOrderModal = () => {
    this.setState({ showReOpenClosedWorkOrderModal: false, reOpenWorkOrderStatus: null, excludeFromFutureExports: false, rework: { reworkReason: '', reworkDetails: '' } });
  }

  openReOpenClosedWorkOrderConfirmModal = () => {
    this.setState({ showReOpenClosedWorkOrderModal: true });
    this.reOpenClosedWorkOrderConfirmModalRef.current.focus();
  }

  onReworkReasonChange = (e, { value }) => {
    this.setState({ rework: { ...this.state.rework, reworkReason: value } });
  }

  onReworkDetailsChange = (e, { value }) => {
    this.setState({ rework: { ...this.state.rework, reworkDetails: value } });
  }

  closeCreateWorkOrderModal = (e, meta, workOrderNumber) => {
    if (workOrderNumber) {
      this.searchRef.current.value = workOrderNumber;
      this.onSearch();
    }
    this.setState({ showCreateWorkOrderModal: false });
  }

  openCreateWorkOrder = () => {
    this.setState({ showCreateWorkOrderModal: true });
  }

  swapWorkOrdersData = async (multipleValuesSearch) => {
    try {
      const { currentProject, user } = this.context;
      const { getWorkOrders } = this.props;
      const { selectedWorkOrders } = this.state;
      this.setState({ swapingWorkOrdersData: true, swapWorkOrdersDataError: null });
      const [workOrderId1, workOrderId2] = selectedWorkOrders;
      await workOrderService.swapWorkOrders(currentProject.id, workOrderId1, workOrderId2, user.id);
      this.setState({ selectedWorkOrders: [], swapingWorkOrdersData: false, showSwapWorkOrdersDataModal: false });
      getWorkOrders(null, null, null, multipleValuesSearch);
    } catch (error) {
      this.setState({ swapingWorkOrdersData: false, swapWorkOrdersDataError: error.message || 'Unexpected error occurred' });
    }
  }

  renderGridColumns = () => {
    const { currentProject } = this.context;

    const defaultColumns = [
      { field: 'workOrderNumber', title: 'Work Order', width: '175px' },
      { field: 'workOrderStatus', title: 'Status', width: '120px' },
      { field: 'workOrderGroups', title: 'Groups', width: '250px' },
      { field: 'workOrderAssignments', title: 'Assignments', width: '250px' },
      { field: 'workOrderLastVisitDate', title: 'Last Visit', width: '150px' },
      { field: 'newMeterNumber', title: 'New Meter', width: '175px' },
      { field: 'newMeterAssetNumber', title: 'Asset', width: '175px' },
      { field: 'newRadioNumber', title: 'New Radio', width: '175px' },
      { field: 'locationId', title: 'Location Id', width: '175px' },
      { field: 'accountNumber', title: 'Account', width: '150px' },
      { field: 'customerName', title: 'Customer', width: '200px' },
      { field: 'street', title: 'Street', width: '200px' },
      { field: 'districtCode', title: 'District', width: '150px' },
      { field: 'substationCode', title: 'Substation', width: '150px' },
      { field: 'circuitCode', title: 'Circuit', width: '150px' },
      { field: 'billingCycleCode', title: 'Cycle', width: '100px' },
      { field: 'routeCode', title: 'Route', width: '120px' },
      { field: 'bookCode', title: 'Book', width: '120px' },
      { field: 'ReadSequence', title: 'Sequence', width: '120px' },
      isWaterProject(currentProject.projectWorkOrderType) ?
        { field: 'meterSize', title: 'Form/Size', width: '150px' }
        :
        { field: 'meterFormFactor', title: 'Form/Size', width: '150px' },
      { field: 'workOrderAttemptNumber', title: 'Attempt', width: '100px' },
      { field: 'workOrderFlaggedAsCNC', title: 'RTC/CNC', width: '110px' },
      { field: 'workOrderFlaggedAs3strike', title: 'RTU/3-S', width: '110px' },
      { field: 'workOrderFlaggedAsAdHoc', title: 'Ad Hoc', width: '100px' },
      { field: 'workOrderIsInBlackOut', title: 'Blackout', width: '100px' },
      { field: 'workOrderFlaggedAsEscalated', title: 'Escalated', width: '120px' },
      { field: 'workOrderNeedsAppointment', title: 'Needs Appointment', width: '180px' },
      { field: 'workOrderNeedsSiteTest', title: 'Specialty', width: '180px' },
    ];

    let results = [];

    if (currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.workOrderListViewCustomization) {
      const workOrderListViewCustomization = currentProject.projectConfiguration.workOrderListViewCustomization;
      workOrderListViewCustomization.forEach(customization => {
        const column = defaultColumns.find(column => column.field === customization.field);
        if (column) {
          results.push({ field: column.field, title: customization.title, width: column.width });
        }
      });
    } else {
      results = [...defaultColumns];
    }

    return results.map(({ field, title, width }) => <Column field={field} title={title} width={width} key={field} />);
  }

  render() {
    const {
      sort,
      selectedWorkOrders,
      showAssignModal,
      error,
      users,
      showMeterDeployDetails,
      showWaterMeterDeployDetails,
      filterTypes,
      selectedWorkOrder,
      pageLimit,
      modalDetailsLoading,
      showRemoveWorkOrderToolBar,
      showExchangeWorkOrdersToolBar,
      showRemoveWorkOrderConfirmModal,
      assigning,
      assignUsersList,
      unassingUsersList,
      photoIndex,
      showWaterDeployDetails,
      showSaveGroupModal,
      searchBy,
      filterBy,
      showReOpenClosedWorkOrderModal,
      reOpeningClosedWorkOrders,
      removingWorkOrder,
      reOpenWorkOrderStatus,
      showCreateWorkOrderModal,
      hideNext,
      loadingTechnicians,
      showSwapWorkOrdersDataModal,
      swapingWorkOrdersData,
      swapWorkOrdersDataError,
      showQualificationWarningModal,
      excludeFromFutureExports,
      rework
    } = this.state;
    const { workOrders, currentOffset, loading, total } = this.props;
    const { user, currentProject } = this.context;
    return (
      <div className='workorders-listview' onKeyPress={this.handleKeyPress}>
        <div className='workorders-listview-tool-bar'>
          <ReOpenClosedWorkOrderConfirmModal
            reOpenWorkOrderStatus={reOpenWorkOrderStatus}
            setReOpenWorkOrderStatus={this.setReOpenWorkOrderStatus}
            loading={reOpeningClosedWorkOrders}
            showModal={showReOpenClosedWorkOrderModal}
            closeModal={this.closeReOpenClosedWorkOrderModal}
            onSubmit={this.reOpenClosedWorkOrder}
            modalRef={this.reOpenClosedWorkOrderConfirmModalRef}
            onExcludeFromFutureExportsChange={this.onExcludeFromFutureExportsChange}
            excludeFromFutureExports={excludeFromFutureExports}
            onReworkReasonChange={this.onReworkReasonChange}
            onReworkDetailsChange={this.onReworkDetailsChange}
            rework={rework}
          />
          {showQualificationWarningModal && <QualificationWarningModal
            showModal={!!showQualificationWarningModal}
            message={showQualificationWarningModal}
            closeModal={this.closeQualificationWarningModal} />}
          {showCreateWorkOrderModal && <CreateWorkOrderModal
            showModal={showCreateWorkOrderModal}
            closeModal={this.closeCreateWorkOrderModal} />}
          {showSaveGroupModal && <SaveWorkOrdersGroup
            showModal={showSaveGroupModal}
            closeModal={this.closeSaveGroupModal}
            selectedWorkOrders={selectedWorkOrders}
            getFilterTypes={this.props.getFilterTypes}
            getWorkOrders={this.props.getWorkOrders}
            searchBy={searchBy}
            filterBy={filterBy}
            unselectWorkOrders={this.unselectWorkOrders}
          />}
          {showAssignModal && <WorkOrdersAssignmentsModal
            title='Assignment'
            iconName='users'
            selectUser={this.selectUser}
            error={error}
            users={users}
            showModal={showAssignModal}
            closeModal={this.closeAssignModal}
            onModalConfirm={this.assignUsers}
            assigning={assigning}
            addUserToAssignedList={this.addUserToAssignedList}
            addUserToUnAssignedList={this.addUserToUnAssignedList}
            assignUsersList={assignUsersList}
            unassingUsersList={unassingUsersList}
          />}
          {showMeterDeployDetails && <MeterDeployDetailsModal
            getWorkOrderDetails={this.onGetWorkOrderDetails}
            hideNext={hideNext}
            setModalDetailsLoading={this.setModalDetailsLoading}
            onNext={this.onNext}
            user={user}
            modalDetailsLoading={modalDetailsLoading}
            selectedWorkOrder={selectedWorkOrder}
            closeModal={this.closeMeterDeployDetails}
            showModal={showMeterDeployDetails}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            currentProject={currentProject}
            updateWorkOrder={this.updateWorkOrder}
          />}
          {showWaterMeterDeployDetails && <WaterMeterDeployDetailsModal
            getWorkOrderDetails={this.onGetWorkOrderDetails}
            hideNext={hideNext}
            setModalDetailsLoading={this.setModalDetailsLoading}
            onNext={this.onNext}
            user={user}
            modalDetailsLoading={modalDetailsLoading}
            selectedWorkOrder={selectedWorkOrder}
            closeModal={this.closeWaterMeterDeployDetails}
            showModal={showWaterMeterDeployDetails}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            currentProject={currentProject}
            updateWorkOrder={this.updateWorkOrder}
          />}
          {showWaterDeployDetails && <WaterDeployDetailsModal
            getWorkOrderDetails={this.onGetWorkOrderDetails}
            hideNext={hideNext}
            setModalDetailsLoading={this.setModalDetailsLoading}
            onNext={this.onNext}
            user={user}
            modalDetailsLoading={modalDetailsLoading}
            selectedWorkOrder={selectedWorkOrder}
            closeModal={this.closeWaterDeployDetails}
            showModal={showWaterDeployDetails}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            currentProject={currentProject}
            updateWorkOrder={this.updateWorkOrder} />}
          {showRemoveWorkOrderToolBar || showExchangeWorkOrdersToolBar ? (
            <>
              {showRemoveWorkOrderToolBar && <RemoveWorkOrderToolBar
                removingWorkOrder={removingWorkOrder}
                showRemoveWorkOrderConfirmModal={showRemoveWorkOrderConfirmModal}
                closeRemoveWorkOrderConfirmModal={this.closeRemoveWorkOrderConfirmModal}
                removeWorkOrders={this.removeWorkOrders}
                removeWorkOrderConfirmModalRef={this.removeWorkOrderConfirmModalRef}
                selectedWorkOrders={selectedWorkOrders}
                openRemoveWorkOrderConfirmModal={this.openRemoveWorkOrderConfirmModal}
                multipleSearchRef={this.multipleSearchRef}
                onMultipleValuesSearch={this.onMultipleValuesSearch}
                toggleRemoveWorkOrderToolBar={this.toggleRemoveWorkOrderToolBar}
              />}
              {showExchangeWorkOrdersToolBar && <ExchangeWorkOrdersToolBar
                selectedWorkOrders={selectedWorkOrders}
                openSwapWorkOrdersDataModal={this.openSwapWorkOrdersDataModal}
                toggleExchangeWorkOrders={this.toggleExchangeWorkOrders}
                onExchangeWorkOrdersSearch={this.onExchangeWorkOrdersSearch}
                showSwapWorkOrdersDataModal={showSwapWorkOrdersDataModal}
                swapWorkOrdersDataError={swapWorkOrdersDataError}
                swapWorkOrdersData={this.swapWorkOrdersData}
                swapingWorkOrdersData={swapingWorkOrdersData}
                closeSwapWorkOrdersDataModal={this.closeSwapWorkOrdersDataModal}
              />}
            </>
          ) : (
            <React.Fragment>
              <Button
                loading={loadingTechnicians}
                onClick={this.openAssignModal}
                disabled={
                  loadingTechnicians ||
                  selectedWorkOrders.length === 0 ||
                  isDisabled(user.userRoles, [ROLES.Viewer])
                }
              >
                <Icon name='users' />
                Assignment
              </Button>
              <Button
                onClick={this.openSaveGroupModal}
                disabled={
                  selectedWorkOrders.length === 0 ||
                  isDisabled(user.userRoles, [ROLES.Viewer])
                }
              >
                <Icon name='list' />
                Save Group
              </Button>
              {isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) &&
                <Dropdown button={true} text={'More'} className='more-dropdown' pointing={false} options={this.moreDropDownOptions()} />
              }
              <div style={{ flex: 1, paddingRight: '5px' }}>
                <MultiSelect
                  onChange={this.onFilterTypeChange}
                  style={{ width: '100%' }}
                  label='Filter by...'
                  data={filterTypes}
                  textField='filterName'
                />
              </div>
              <Search
                searchRef={this.searchRef}
                onSearch={this.onSearch}
                placeholder='Search by old, new, cust, acct, street, loc...'
              />
            </React.Fragment>
          )}
        </div>
        <Loader loading={loading} />
        <Grid
          onClick={e => e.preventDefault()}
          className='workorders-listview-grid'
          sortable
          sort={sort}
          onSortChange={this.onSortChange}
          style={{ height: '92%' }}
          data={orderBy(workOrders, sort)}
          onSelectionChange={this.selectionChange}
          onHeaderSelectionChange={this.headerSelectionChange}
          onRowClick={this.rowClick}
          selectedField='selected'
          pageable={{ pageSizes: [50, 100, 500, 1000] }}
          total={total}
          onPageChange={this.pageChange}
          skip={currentOffset}
          take={pageLimit}
        >
          <GridToolbar>
            <div style={{ display: 'flex' }}>
              <p style={{ width: '100%', textAlign: 'right' }}>
                Selected rows ({selectedWorkOrders.length})
              </p>
            </div>
          </GridToolbar>
          {workOrders.length > 0 && <Column
            field='selected'
            width='50px'
            headerSelectionValue={
              workOrders.findIndex(dataItem => dataItem.selected === false) ===
              -1
            }
          />}
          <Column
            field=''
            title='View'
            width='150px'
            cell={this.PreviewButtonRenderer}
            headerClassName='workorder-details-column'
          />
          {this.renderGridColumns()}
        </Grid>
      </div >
    );
  }
}

export default withApollo(WorkOrdersListView);
