import React, { useState, useEffect, useContext } from 'react';

import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';

import AppContext from '../../../core/context/app.context';

import { completeCall } from '../../../core/services/workOrder.service';





export const CallStatusCommentModal = ({ closeModal, setLoading, workOrderId, userId, fetchWorkOrder }) => {
    const [success, setSuccess] = useState(false);
    const [comment, setComment] = useState('');

    const handleForm = (evt) => {
        setComment(evt.value);
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        try {
            setLoading(true);
            await completeCall({ userId, workOrderId, comment });
            setLoading(false);
            setSuccess(true);
            setTimeout(() => closeModal({ fromDetailsModal: true }), '1400');
            await fetchWorkOrder(workOrderId)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Dialog width={'90%'} height={'50%'} title={'Update Call Status'} onClose={closeModal}>
                <div id='confirm_appointment'>
                    <form className='confirm-details-contact-container'>
                        <FieldWrapper style={{width: '100%'}}>
                            <Label>Comment:</Label>
                            <div className={"k-form-field-wrap"}>
                            <TextArea
                                required={true}
                                style={{height:134}}
                                disabled={false}
                                name={"additionalNotes"}
                                onChange={handleForm}
                                value={comment}
                                rows={7}
                            />
                            </div>
                        </FieldWrapper>
                        <Button disabled={!comment.length > 0} style={{ margin: '10px' }} primary={true} onClick={handleSubmit}>Submit</Button>
                    </form>
                    { success && <div style={{width: '100%', textAlign: 'center', background: '#80cf80', color: 'black', borderRadius: '4px'}}>Success!</div>}
                </div>
            </Dialog>
        </>
    );
}