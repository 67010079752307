import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Notification as KendoNotification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import config from '../../../config';
import { getToken } from '../../../core/services/auth.service';

const Notification = () => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const aws_client = new AWSAppSyncClient({
      region: config.region,
      url: config.apolloClient.uri,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => {
          const token = await getToken();
          return token ? token : '';
        }
      },
      disableOffline: true
    });

    aws_client.subscribe({
      query: gql`
        subscription OnCreateVersion {
          onCreateVersion(versionType: "Web") {
            id
          }
        }
      `
    }).subscribe({
      next: ({ data }) => {
        console.log(data);
        setShowNotification(true);
      }
    });
  }, []);

  const reload = () => {
    window.location.reload();
  }

  return (
    <div style={{ position: 'absolute', bottom: 0, right: 23 }}>
      <Fade enter={true} exit={true}>
        {showNotification && <KendoNotification
          type={{ style: 'info', icon: true }}
          closable={true}
          onClose={() => setShowNotification(false)}
        >
          <span>This application version has been updated: <span onClick={reload}><b style={{ cursor: 'pointer' }}>Refresh Now</b></span></span>
        </KendoNotification>}
      </Fade>
    </div>
  );
}

export default Notification;